<template>
  <div class="">
    <div class="p-2">
      <h6 class="title text-uppercase font-weight-bold mb-2 mt-4">
        <i class="bx bx-user-circle mr-1 text-6 position-relative top-5"></i>
        Reset Password
      </h6>
      <div v-if="!TokenVerified">
        <p v-if="!TokenSent" class="mb-3">
          Please enter your Account Number, email, telephone or nickname and
          password to continue
        </p>
        <form v-if="!TokenSent" @submit.prevent="SendToken" method="post">
          <div class="form-group mb-3">
            <div class="input-group">
              <input
                v-model="ContactNo"
                type="text"
                class="form-control form-control-lg"
                placeholder="Account Number"
              />
              <span class="input-group-append">
                <span class="input-group-text">
                  <i class="bx bx-user text-4"></i>
                </span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col text-right mb-3">
              <button
                :disabled="!ContactNo"
                type="submit"
                class="w3-button w3-red mt-2"
              >
                Continue
              </button>
              <button
                @click.prevent="CancelReset"
                type="button"
                class="w3-button w3-light-grey mt-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
        <form v-if="TokenSent" @submit.prevent="VerifyToken" method="post">
          <div class="form-group mt-4 mb-3">
            <label>Enter verification token received</label>
            <div class="input-group">
              <input
                v-model="Token"
                type="text"
                class="form-control form-control-lg"
                placeholder="Verification token"
              />
              <span class="input-group-append">
                <span class="input-group-text">
                  <i class="bx bx-user text-4"></i>
                </span>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col text-right mb-3">
              <button
                :disabled="!Token"
                type="submit"
                class="w3-button w3-red mt-2"
              >
                Verify
              </button>
              <button
                @click.prevent="CancelReset"
                type="button"
                class="w3-button w3-light-grey mt-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>

      <form v-if="TokenVerified" @submit.prevent="UpdatePassword" method="post">
        <div class="form-group mt-4 mb-3">
          <label>Enter a new password</label>
          <div class="input-group">
            <input
              v-model="NewPassword"
              type="password"
              class="form-control form-control-lg"
              placeholder="New Password"
            />
            <span class="input-group-append">
              <span class="input-group-text">
                <i class="bx bxs-lock text-4"></i>
              </span>
            </span>
          </div>
          <label>Repeat new password</label>
          <div class="input-group">
            <input
              v-model="RePassword"
              type="password"
              class="form-control form-control-lg"
              placeholder="Repeat password"
            />
            <span class="input-group-append">
              <span class="input-group-text">
                <i class="bx bxs-lock text-4"></i>
              </span>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col text-right mb-3">
            <button
              :disabled="!NewPassword || !RePassword"
              type="submit"
              class="w3-button w3-red mt-2"
            >
              Update Password
            </button>
            <button
              @click.prevent="CancelReset"
              type="button"
              class="w3-button w3-light-grey mt-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { Utility } from "@/helpers/utilities";
import axios from "axios";
export default {
  components: {},
  setup(_, { emit }) {
    const TokenSent = ref(false);
    const ContactNo = ref("");
    const Token = ref("");
    const TokenVerified = ref(false);
    const NewPassword = ref("");
    const RePassword = ref("");
    const PasswordChanged = ref(false);

    const CancelReset = () => {
      emit("cancelPasswordReset");
      TokenSent.value = false;
      TokenVerified.value = false;
      ContactNo.value = "";
      Token.value = "";
    };
    const Completed = () => {
      emit("completedPasswordReset");
      TokenSent.value = false;
      TokenVerified.value = false;
      ContactNo.value = "";
      Token.value = "";
    };
    const SendToken = () => {
      if (!ContactNo.value) {
        Utility.Error("Please enter your Account number to continue");
        return;
      }
      Utility.showIndicator();
      axios({
        method: "get",
        url: "/api/Security/ResetSendToken",
        params: { UserID: ContactNo.value },
      }).then((response) => {
        Utility.hideIndicator();
        if (response.data.status === "success") {
          TokenSent.value = true;
          Utility.Success(response.data.result);
        } else {
          TokenSent.value = false;
          Utility.Error(
            response.data.result ? response.data.result : "An error occured"
          );
        }
      });
    };
    const VerifyToken = () => {
      if (!ContactNo.value) {
        Utility.Error("Please enter your Account number to continue");
        return;
      }
      if (!Token.value) {
        Utility.Error("Please enter the token received to continue");
        return;
      }
      TokenVerified.value = false;
      Utility.showIndicator();
      axios({
        method: "post",
        url: "/api/Security/VerifyToken",
        data: { UserID: ContactNo.value, otp: Token.value },
      }).then((response) => {
        Utility.hideIndicator();
        if (response.data.status === "success") {
          TokenVerified.value = true;
          Utility.Success(response.data.result);
        } else {
          Utility.Error(response.data.result);
        }
      });
    };
    const UpdatePassword = () => {
      if (!ContactNo.value) {
        Utility.Error("Please enter your Account number to continue");
        return;
      }
      if (!NewPassword.value) {
        Utility.Error("Please enter your new password to continue");
        return;
      }
      if (!RePassword.value) {
        Utility.Error("Please repeat your new password to continue");
        return;
      }
      if (NewPassword.value !== RePassword.value) {
        Utility.Error("The new and repeated password mismatch");
        return;
      }
      PasswordChanged.value = false;
      Utility.showIndicator();
      axios({
        method: "post",
        url: "/api/Security/UpdatePassword",
        data: { UserID: ContactNo.value, NewPassword: NewPassword.value },
      }).then((response) => {
        Utility.hideIndicator();
        if (response.data.status === "success") {
          PasswordChanged.value = true;
          Utility.Success(
            "Password updated successfully. You can now login with the new password"
          );
          Completed();
        } else {
          Utility.Error(response.data.result);
        }
      });
    };
    return {
      SendToken,
      ContactNo,
      TokenSent,
      Token,
      VerifyToken,
      TokenVerified,
      NewPassword,
      RePassword,
      UpdatePassword,
      PasswordChanged,
      CancelReset,
    };
  },
};
</script>
