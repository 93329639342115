<template>
  <section role="main" class="content-body">
    <header class="page-header">
      <h2>{{ title }}</h2>
    </header>

    <div class="p-4 w3-white">
      <div v-if="fetching" class="loading_spinner_box">
        <div class="loading_spinner"></div>
      </div>
      <div v-else class="row">
        <div v-if="UserProfile" class="col-md-8">
          <div class="p-4 mb-4">
            <h3 class="mb-3 w3-left">
              {{ UserProfile.Name }} {{ UserProfile.Name_2 }}
            </h3>
            <button
              v-if="!PasswordReset"
              @click.prevent="PasswordReset = true"
              type="button"
              class="w3-button w3-white w3-border w3-right"
            >
              Reset Password
            </button>
            <div class="mb-4" style="clear: both"></div>
            <div v-if="PasswordReset" class="max-width400">
              <ResetPassword
                @completedPasswordReset="PasswordReset = false"
                @cancelPasswordReset="PasswordReset = false"
              />
            </div>
            <div v-if="!PasswordReset" class="p-4">
              <template v-for="profile in profileData" :key="profile">
                <dl v-if="UserProfile[profile]" class="row w3-border-bottom">
                  <dt class="col-sm-5">{{ profile.replaceAll("_", " ") }}</dt>
                  <dd class="col-sm-7">
                    {{ UserProfile[profile] }}
                  </dd>
                </dl>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { useStore } from "vuex";
import { computed, onBeforeMount, ref } from "vue";
import ResetPassword from "@/components/ResetPassword.vue";
export default {
  components: { ResetPassword },
  setup() {
    const store = useStore();
    const title = ref("Profile");
    const PasswordReset = ref(false);
    const UserProfile = computed(() => store.state.Profile);
    const fetching = computed(() => store.state.fetching.Profile);
    if (typeof UserProfile.value.timestamp === "undefined") {
      store.dispatch("Profile");
    }
    const profileData = [
      "No_",
      "Name",
      "Name_2",
      // "Search_Name",
      "First_Name",
      "Middle_Name",
      "Surname",
      "Address",
      "Address_2",
      "City",
      "Phone_No_",
      "E_Mail",
      // "Telex_No_",
      // "Territory_Code",
      // "Currency_Code",
      // "Language_Code",
      // "Country_Region_Code",
      "Fax_No_",
      "VAT_Registration_No_",
      "Post_Code",
      "County",
      // "Type",
      "Company_No_",
      "Company_Name",
      // "Lookup_Contact_No_",
      "Job_Title",
      "Initials",
      "Extension_No_",
      "Mobile_Phone_No_",
      // "Organizational_Level_Code",
      // "Exclude_from_Segment",
      // "Correspondence_Type",
      // "Salutation_Code",
      // "Search_E_Mail",
      // "Last_Time_Modified",
      "E_Mail_2",
      "SMS_Phone_Number",
      "Fund_Account",
      "Account_Number",
      "SMS_Mobile_No_",
      "Creation_Date",
      "Date_of_Birth_DMYY_",
      "BVN",
      "Referred_By",
      "Religion",
      "State_of_Origin",
      "L_G_A",
      "Employment_Status",
      "Annual_Income",
      "TIN",
      // "Investment_Horizon",
      "Dividend_Payment",
      "Occupation",
      "Employer_Name",
      "Employer_Address",
      "Industry",
      "Office_Phone_No_",
      "Hear_About_us_",
      "Type_of_ID",
      "ID_No_",
      "Bank_Name",
      "Branch_Name",
      "Account_Name",
      "Account_No_",
      "Sort_Code",
      "ID_Expiry_Date",
      "Parent_ID",
      // "Birthday_Month",
      // "Sukuk_Investor",
      "Personal_Identification_No_",
      // "Priority_Filter",
      // "Picture",
    ];
    onBeforeMount(() => {
      store.dispatch("resetIdleTime");
    });
    return {
      title,
      UserProfile,
      profileData,
      fetching,
      PasswordReset,
    };
  },
};
</script>
